<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="Parolamı Unuttum" :array="[{islink:true,url:'/',text:'Ana Sayfa'},{islink:false,url:'',text:'Parolamı Unuttum'}]"/>
    </section>
    <!--/Breadcrumb-->

    <!--Forgot password-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-md-6 d-block mx-auto">
            <div class="card mb-0">
              <div class="card-header">
                <h3 class="card-title">Parolamı Unuttum</h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label text-dark" for="email"
                    >E-Posta Adresi</label
                  >
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="form-control"
                    placeholder="E-Posta Girin"
                  />
                </div>
                <div class="form-footer">
                  <button
                    id="sendButton"
                    @click="send"
                    class="btn btn-primary btn-block"
                  >
                    Bağlantı Gönder
                  </button>
                </div>
                <div class="text-center text-dark mt-3">
                  <router-link to="/login">Giriş ekranına</router-link> geri
                  dön.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/Forgot password-->
  </div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  metaInfo() {
    return {
      title: "Parolamı Unuttum - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Parolamı Unttum - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Parolamı Unuttum - Terapi Vitrini",
      "Parolamı Unttum - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
  },
  data() {
    return {
      pageGet: [],
    };
  },
  components: { Breadcrumb },
  mounted() {
    this.backToTop();
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    send() {
      let email = document.getElementById("email").value;
      if (email == "") {
        this.$vToastify.warning("Mail Adresi girilmedi", "Dikkat!");
      } else {
        document.getElementById("sendButton").disabled = true;
        document.getElementById("sendButton").innerHTML =
          "Sıfırlama bağlantısı gönderiliyor..";
        let pageInfo = {
          email: email,
        };
        this.$store.dispatch("passwordReset", pageInfo).then((value) => {
          document.getElementById("sendButton").disabled = true;
          document.getElementById("sendButton").innerHTML = "Bağlantı Gönder";
          if (value.type == "success") {
            this.$vToastify.success(
              "Sıfırlama Bağlantısı Gönderildi",
              "Başarılı!"
            );
          }
          if (value.type == "error") {
            this.$vToastify.error("Mail Adresi Bulunamadı", "Dikkat!");
          }
        });
      }
    },
  },
};
</script>